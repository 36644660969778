"use client"
import Skeleton from "@mui/material/Skeleton"

import { SkeletonProps } from "@mui/material/Skeleton"
import { useTheme } from "@mui/material/styles"

import styles from "./Loader.module.scss"

import { Loading } from "@/components/common/loading"

import { create } from "@/helpers/bem"

const bem = create(styles, "Loader")

export type LoaderProps = {
  skeletonProps?: SkeletonProps
}

export const Loader = ({ skeletonProps = {} }) => {
  const theme = useTheme()
  const themeModifier = {
    [theme.palette.mode]: true,
  }
  return (
    <div className={bem(undefined, themeModifier)}>
      <Loading size="xl" />
      <Skeleton
        className={bem("skeleton")}
        variant="rectangular"
        {...skeletonProps}
      />
    </div>
  )
}
